<template>
	<div class="worktime modal" @click.self="hideSelf">
		<div class="worktime__container">
			<div
				class="worktime__close"
				@click="hideSelf"
			>✕</div>
			<div class="worktime__content">
				<h3 class="worktime__title">График работы салона в праздничные дни</h3>
				<p class="worktime__subtitle">
					Уважаемые клиенты! Информируем вас о режиме работы нашего салона в новогодние праздники:

					<br><br>31 декабря дилерский центр работает до 15:00
					<br>1 и 2 января выходные дни
					<br>3 и 4 января рабочие часы с 10:00 до 19:00

					<br><br>С 5 января мы работаем для вас ежедневно, в обычном режиме с 9.00 до 21.00.
					<br><br><b>С Наступающим Новым 2023 Годом!</b>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "full-worktime",
	emits: ['close'],
	methods: {
		hideSelf() {
			this.$emit("close");
		},
	},
};
</script>

<style scoped lang="scss">
.worktime {
	background: rgba(0,0,0, 0.5);
	width: 100%;
	height: 100vh;
	position: fixed;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 12000000000;


	&__container {
		position: relative;
		width: 580px;
		max-width: 90%;
		max-height: 90vh;
		overflow: auto;
		background: #fff;
		color: #000;
		padding: 40px;
		cursor: default;
	}

	&__title {
		margin-bottom: 30px;
		text-align: center;
		font-weight: 400;
		letter-spacing: 0.01em;
		line-height: 1.2;
		font-size: 26px;
	}

	&__subtitle {
		font-size: 18px;
		margin: 0 auto;
	}

	&__close {
		font-size: 25px;
		position: absolute;
		right: 15px;
		top: 15px;
		cursor: pointer;
	}
}

@media (max-width: 800px) {
	.worktime {

		&__container {
			padding: 20px;
		}

		&__title {
			font-size: 18px;
			margin-bottom: 20px;
		}

		&__subtitle {
			font-size: 14px;
		}

		&__close {
			top: 10px;
			right: 10px;
		}
	}
}
</style>
