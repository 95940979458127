<template>
	<div class="block-footer-container" :class="[device_platform]">
		<div class="footer-text">
			<br />*Выгода в размере 640 000 рублей указана для автомобилей «Geely
			Tugella (все комплектации) 2023 года выпуска, и состоит из специальной
			выгоды в размере 150 000 руб., выгоды при покупке в кредит в размере 160
			000 рублей и выгоды по программе «Трейд-ин» в размере 290 000 рублей.
			Выгода 330 000 рублей на указанные автомобили предоставляется при сдаче в
			Трейд-ин автомобиля «Geely» или другой популярной марки (список марок
			уточняйте в дилерских центрах «Geely»). Программа действует с 01.07.2024
			по 31.07.2024 г.

			<br /><br /><a
				href="../documents/rules.pdf"
				target="_blank_"
				class="footer__link"
				>Правила пользования сайтом</a
			>
			<br /><br /><a
				href="../documents/requisites.pdf"
				target="_blank"
				class="footer__link"
				>Реквизиты</a
			>

			<br /><br />ООО «Автостиль» <br />Юридический адрес: 198152, город
			Санкт-Петербург, Краснопутиловская ул., д. 65 литер а, помещ. 5-н №3
			<br />ОГРН: 1197847129468 <br />ИНН: 7805752165 <br />©
			{{ currentYear }} «АВТОСТИЛЬ GEELY» - официальный дилер Geely.
			<div class="hidden-phone">8 (495) 135-40-71</div>
		</div>
	</div>
</template>

<script>
	import Mixin from "../common/mixin";

	export default {
		name: "block-footer",
		mixins: [Mixin],
		data() {
			return {
				currentYear: "",
			};
		},
		mounted() {
			this.currentYear = new Date().getFullYear();
		},
	};
</script>

<style scoped lang="scss">
	@import "../styles/constants.scss";

	.mobile {
		&.block-footer-container {
			.footer-text {
				padding: 30px 10px 40px;
			}
		}
	}

	.block-footer-container {
		width: 100%;
		max-width: 1920px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		background: #efefef;
		.footer-text {
			width: 100%;
			padding: 30px 50px 40px;
			margin: 0 auto;
			text-align: center;
			font-weight: 400;
			font-size: 14px;
			color: black;
		}

		.footer__link {
			text-decoration: underline;
			cursor: pointer;
		}
	}

	.hidden-phone {
		position: absolute;
		z-index: -100;
	}
</style>
