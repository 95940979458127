<template>
  <a
    :href="'tel:+' + raw"
    class="phone office-phone-link"
    id="phone"
    @click="handleClick"
    ><slot />
  </a>
</template>

<script>
export default {
  name: "base-phone",
  props: {
    raw: {
      type: String,
      required: true,
    },
  },
  methods: {
    handleClick() {
      window.ym && window.ym(96856485, "reachGoal", "click_phone");
    },
  },
};
</script>

<style lang="scss" scoped></style>
