import { render, staticRenderFns } from "./block-offer.vue?vue&type=template&id=6cac5ede&scoped=true"
import script from "./block-offer.vue?vue&type=script&lang=js"
export * from "./block-offer.vue?vue&type=script&lang=js"
import style0 from "./block-offer.vue?vue&type=style&index=0&id=6cac5ede&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cac5ede",
  null
  
)

export default component.exports