<template>
  <div class="block-choice-container" :class="[device_platform]">
    <div class="header-text">
      Geely в наличии
      <img  class="header-text__bg" src="../images/background/bg_stock.png" alt="">
    </div>
    <!-- <div
      class="bottom-layer"
      v-if="device_platform === 'desktop' && cars_to_show.length"
    ></div> -->
    <div class="bg-container">
        <div class="content-container">
            <selector-bar
              :values="selectors"
              @selectModel="selectModel"
              :cur_value="cur_selector"
              :b_cars_car="b_cars_car"
            ></selector-bar>
            <transition name="appear">
              <div
                class="blue-bg"
                v-if="
                  !['all', 'special'].some((el) => el === cur_selector) ||
                  device_platform !== 'mobile'
                "
              >
                <transition name="appear"></transition>
                <!-- <div class="table-header" v-if="device_platform !== 'mobile'"></div> -->
              </div>
            </transition>
            <div class="search-container">
              <div class="search-item" v-for="item in cars_to_show">
                <div
                  class="image"
                  :class="[
                    orientation(item),
                    {
                      image_special: item.complectation_name.includes('Яндекс.Авто'),
                    },
                  ]"
                >
                  <img
                    :src="item.images"
                    @click="
                      getCall({
                        type: 'sale',
                        form: 'complectations',
                        button_text: 'ПОЛУЧИТЬ ЭТО ПРЕДЛОЖЕНИЕ',
                      })
                    "
                  />
                </div>
                <table class="info">
                  <tr>
                    <td class="title">Комплектация</td>
                    <td class="value">{{ item.complectation_name }}</td>
                  </tr>
                  <tr>
                    <td class="title">Технические характеристики</td>
                    <td class="value">{{ item.modification }}</td>
                  </tr>
                  <tr>
                    <td class="title">Год</td>
                    <td class="value">{{ item.year }}</td>
                  </tr>
                  <tr>
                    <td class="title">Номер</td>
                    <td class="value">{{ item.vin }}</td>
                  </tr>
                  <tr>
                    <td class="title">Цвет</td>
                    <td class="value">{{ item.color }}</td>
                  </tr>
                  <!-- <tr>
                    <td class="title">Цена</td>
                    <td class="value price">{{ +item.price | finance }} ₽</td>
                  </tr> -->
                </table>
                <div class="price-block">
                    от <span class="value price price-block__price">{{ +item.price | finance }}</span> рублей
                </div>
                <div
                  class="btn-blue"
                  @click.stop="
                    getCall({
                      type: 'sale',
                      form: 'complectations',
                      callback_title: 'ПОЛУЧИТЬ ЛУЧШУЮ ЦЕНУ',
                    })
                  "
                >
                  ПОЛУЧИТЬ ЛУЧШУЮ ЦЕНУ
                </div>
              </div>
            </div>

            <div class="btn-white" v-show="show_more_btn" @click="incCarsNum">
              ПОКАЗАТЬ БОЛЬШЕ КОМПЛЕКТАЦИЙ
            </div>
          </div>
    </div>

  </div>
</template>

<script>
import SelectorBar from "./selector-bar";
import Mixin from "@/common/mixin";
import Finance from "../common/finance";
import CARS_DATA from "../common/data";
import axios from "axios";

// const COMMON_CARS_SELECTORS = [{name: 'Atlas'}, {name: 'Emgrand X7'}, {name: 'Emgrand 7'}];TUGELLA
const COMMON_CARS_SELECTORS = [
  { name: "Coolray" },
  { name: "Tugella" },
  // { name: "Emgrand X7" },
  // { name: "GS" },
  { name: "Atlas" },
  { name: "Atlas Pro" },
];
const ALL_CARS_SELECTOR = {
  name: "all",
  text: "Все модели",
};

export default {
  name: "block-choice",
  components: { SelectorBar },
  directives: {},
  mixins: [Mixin, Finance],
  props: ["b_cars_car"],
  data() {
    return {
      cars: [],
      cars_num: 4,
      cur_selector: "Coolray",
      cur_comp: "",
      cur_engine: "",
      dev_mode: false,
    };
  },
  created() {
    if (this.dev_mode) {
      this.cars = CARS_DATA.models;
    } else {
      const req_url = location.host.includes("localhost")
        ? "/cars.json"
        : "/back/cars/";

      axios
        .get(req_url, {
          // /skoda_xml/
        })
        .then((res) => {
          let cars = res.data.models;
          this.cars = cars || [];
        });
    }
  },
  computed: {
    selectors() {
      return [ALL_CARS_SELECTOR].concat(COMMON_CARS_SELECTORS);
    },
    prefiltered_cars() {
      if (this.cur_selector === "all") {
        return this.cars;
      }

      return this.cars.filter((car) => car.model.includes(this.cur_selector));
    },
    filtered_cars() {
      let ret_cars = this.prefiltered_cars;

      if (this.cur_comp.length > 0) {
        ret_cars = ret_cars.filter((car) => car.package === this.cur_comp);
      }
      if (typeof this.cur_engine === "number" || this.cur_engine.length > 0) {
        ret_cars = ret_cars.filter((car) => car.volume === this.cur_engine);
      }

      ret_cars.sort((a, b) => a.price - b.price);

      return ret_cars;
    },
    cars_to_show() {
      return this.filtered_cars.slice(0, this.cars_num);
    },
    show_more_btn() {
      return this.filtered_cars.length > this.cars_num;
    },
  },
  methods: {
    selectModel(name) {
      this.cars_num = 4;
      this.cur_comp = "";
      this.cur_engine = "";
      this.cur_selector = name;
    },
    setComp(comp) {
      this.cars_num = 4;
      this.cur_comp = comp;
    },
    setEngine(engine) {
      this.cars_num = 4;
      this.cur_engine = engine;
    },
    getName(car) {
      return car.modification.split(" ")[0];
    },
    getInfo(item) {
      let patrol = item.patrol ? item.patrol : "";
      return (
        this.getName(item).toUpperCase() +
        " " +
        item.package +
        " " +
        item.volume +
        "л. " +
        patrol +
        ", " +
        item.hp +
        " л.с. " +
        item.transm
      );
    },
    incCarsNum() {
      this.cars_num += 4;
    },
    orientation(item) {
      if (!item.img_orientation) {
        return;
      }
      if (item.img_orientation == 6) {
        return "left-to-right";
      }
      if (item.img_orientation == 8) {
        return "right-to-left";
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/constants.scss";

.desktop {
  .search-item {
    margin-right: calc(8% / 3);

    &:nth-child(4n) {
      margin-right: 0;
    }
  }
}

.mobile {
  .content-container {
    width: calc(100% - 10px);
    padding: 0;
  }

  .header-text {
    font-size: 35px;
  }

  .search-container {
    flex-direction: column;
  }

  .search-item {
    width: 100%;
    max-width: calc(100% - 40px);
    margin: 10px auto;
  }

  .offer-btn {
    margin: 10px 0;
    width: 275px;
  }

  .mobile-title {
    position: absolute;
    left: 5px;
    top: 20px;
    font-size: 17px;
    font-weight: 300;
  }

  &.block-choice-container {
    margin-top: 0;
  }
  .value {
    font-size: 13px;
  }
  .price-block__price {
    font-size: 24px;
  }
}

.tablet {
  .search-container {
    justify-content: space-around;
  }
  .search-item {
    width: 50%;
    margin: 2px 10px 40px;
    max-width: 380px;
  }
}

.block-choice-container {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 100px;
  position: relative;
}

.bottom-layer {
  width: 100%;
  max-width: 1920px;
  left: 50%;
  top: 80px;
  transform: translateX(-50%);
  height: 670px;
  position: absolute;
  background: url("../images/blue2.png");
  background-size: cover;
}

.bg-container {
  width: 100%;
  background: #356fb1;
}

.content-container {
  max-width: 1500px;
  width: 100%;
  margin: 40px auto 0;
  padding-bottom: 1px;
  position: relative;
}

.dropdown-section {
  height: 90px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.dropdown-box {
  margin-left: 20px;
}

.table-header {
  width: 100%;
  border-top: 10px solid $blue;
  border-bottom: 20px solid #efefef;
}

.blue-bg {
  background: #1f6fb6;
}

.search-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}

.search-item {
  width: 23%;
  margin: 2px 0px 40px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0 0 3px #ccc;
}

.btn-blue {
  margin: 15px auto 25px;
}

.image {
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-start;

  img {
    width: 100%;
    object-fit: contain;
    cursor: pointer;
    image-orientation: none;
  }

  &_special::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 72px;
    height: 54px;
    background-image: url("../images/utils/percentage.png");
    background-repeat: no-repeat;
  }
}

.offer {
  cursor: pointer;
  color: #4ba82e;
  margin-top: 10px;
}

.info {
  padding: 10px;
  font-size: 15px;
}

.title {
  font-weight: 300;
}

.value {
  font-weight: 500;
}

.title,
.value {
  width: 50%;
  padding: 5px 5px;
}

.price {
  font-weight: 500;
  text-decoration: line-through;
}

.offer-btn {
  width: 295px;
  height: 50px;
  text-align: center;
  background: #4ba82e;
  color: white;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  cursor: pointer;

  &:hover:not(.disabled) {
    background: var(--button_common_hover);
  }
}

.btn-white,
.btn-white:hover {
  width: 300px;
  margin: 30px auto;
}

.left-to-right {
  overflow: hidden;
  img {
    transform: rotate(90deg);
  }
}
.right-to-left {
  overflow: hidden;

  img {
    transform: rotate(-90deg);
  }
}

.header-text {
  position: relative;
  margin-bottom: 40px;

  &__bg {
    position: absolute;
    bottom: -130px;
    left: 0;

    @media (max-width: 992px) {
      bottom: -155px;
    }      
  }
}

.price-block {
  font-size: 14px;
  padding: 10px 0;

  &__price {
    font-size: 24px;
    text-decoration: line-through;
  }
}

</style>
