<template>
  <div @click="closeByOutside" class='full-gallery'>
    <div class="full-gallery__modal">

      <div class="full-gallery__close" @click="close">
        <img
          src="../../../images/icons/close.svg"
          alt=""
        />
      </div>

      <div ref="swiper" class="swiper">
        <div class="swiper-wrapper">
          <div
            v-for="i in slidesNumber"
            :key="`slide-${i}`"
            class="swiper-slide"
          >
            <img :data-src="getSlideImg(i)" class="swiper-img swiper-lazy">
          </div>
        </div>

        <div class="swiper-button-prev swiper-button">
          <img
            src="../../../images/icons/arrow-left.svg"
            alt=""
          />
        </div>
        <div class="swiper-button-next swiper-button">
          <img
            src="../../../images/icons/arrow-right.svg"
            alt=""
          />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'

export default {
  name: 'full-gallery',
  emits: ["close"],
  components: {},
  props: {
    index: Number,
    model: String,
    slidesNumber: {
      type: Number,
      default: 5,
    }
  },
  data(){
    return{
      swiper: null,
    }
  },
  methods:{
    getSlideImg(i){
      return require(`../../../images/cars/gallery/${this.model}/${i}.jpg`);
    },
    close(){
      this.$emit('close')
    },
    closeByOutside(e) {
      if(e.target == e.currentTarget)
        this.close()
    },
    closeByEsc(e) {
      if(e.code == 'Escape')
        this.close()
    },
  },
  mounted() {
    document.addEventListener('keydown', this.closeByEsc)
    const swiper = new Swiper(this.$refs.swiper, {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
      },
      spaceBetween: 50,
      grabCursor: true,
      lazy: true,
      preloadimages: false,
    })
    console.log(this.index)
    swiper.slideTo(this.index, 0)
  },

  destroyed() {
    document.removeEventListener('keydown', this.closeByEsc)
  },
};
</script>

<style lang='scss' scoped>
@import "@/styles/constants.scss";

.full-gallery {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.62);

  &__modal {
    position: relative;
    width: 800px;
    max-width: 90vw;
    height: 600px;
    max-height: 90vh;
    
    @media (orientation: portrait) {
      max-height: calc(3 / 4 * 100vw);
    }
  }

  &__slide {

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  
  &__close {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    position: absolute;
    right: -100px;
    top: -50px;
    width: 30px;
    height: 30px;
    font-size: 30px;
    font-weight: bold;
    color: #fff;
    text-shadow: 1px 1px 2px #000;
    cursor: pointer;
    z-index: 200;
    transition: 0.3s;

    @media (max-width: 640px) {
      font-size: 22px;
    }
  }
}

.swiper {
  width: 100%;
  height: 100%;
  overflow: hidden;

  &-slide {
    width: 100%;
    height: 100%;
  }

  &-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &-button{
    background-image: none;
    position: absolute;
    top: 50%;
    translate: 0 -50%;
    z-index: 2;
    transition: 0.3s;
    display: flex;

    &-prev{
      left: -50px;
      right: auto;
      transform: none;
      justify-content: flex-end;
    }

    &-next{
      right: -50px;
      left: auto;
      justify-content: flex-start;
    }
  }
}

@media (max-width: 1200px) {
  .full-gallery {
    &__close {
      width: 20px;
      height: 20px;
      right: 0;
    }
  }

  .swiper {
    &-button{
      img {
        width: 20px;
      }

      &-prev{
        left: -40px;
      }

      &-next{
        right: -40px;
      }
    }
  }
}

@media (max-width: 640px) {
  .full-gallery {
    &__close {
      width: 15px;
      height: 15px;
      top: -40px;
    }
  }

  .swiper {
    &-button{
      img {
        width: 15px;
      }

      &-prev{
        left: -30px;
      }

      &-next{
        right: -30px;
      }
    }
  }
}

</style>
