<template>
    <div class="block-offer-container"
         :class="device_platform">
        <div class="content-container">
            <div class="header">
                Оставьте номер, мы перезвоним вам прямо сейчас!
            </div>
            <div class="callback-container">
                <callback-input v-on="$listeners"
                                form_class="agreement-invert"
                                :data="{type: 'sale', form: 'contacts'}">
                    ОСТАВИТЬ ЗАЯВКУ
                </callback-input>
            </div>
        </div>
    </div>
</template>

<script>
    import Mixin from '../common/mixin';
    import CallbackInput from "./callback-form/callback-input";

    export default {
        name: "block-offer",
        components: {CallbackInput},
        mixins: [Mixin],
        data() {
            return {

            }
        },
        methods: {

        }
    }
</script>

<style scoped lang="scss">
@import "../styles/constants.scss";

    .tablet {
        &.block-offer-container {
            height: 700px;
        }

        .callback-container {
            top:calc(50% - 55px);
            left: 20px;
        }
    }

    .mobile {
        &.block-offer-container {
            height: 400px;
        }

        .header {
            font-size: 24px;
        }

        .callback-container {
            width: 300px;
            top:calc(100% - 150px);
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .webp {
        .block-offer-container {
            background: url("../images/background/offer.jpg") no-repeat center;
            background-size: cover;
        }
    }

    .block-offer-container {
        width: 100%;
        max-width: 1920px;
        height: 700px;
        background: url("../images/background/bg-february-coolray-2.jpg") no-repeat center;
        background-size: cover;
        position: relative;
    }

    .content-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .header {
        padding: 20px 0;
        width: 100%;
        font-size: 30px;
        text-align: center;
        font-weight: 600;
        background: $dark-blue;
        color: #fff;
    }

    .callback-container {
        position: absolute;
        left: 10vw;
        top:calc(50% - 55px);
    }

</style>
