<template>
    <div class="selectors-bar"
         :class="[device_platform]">
        <div class="pseudo-background"
             :style="c_bg_position"
             ref="bg"></div>
        <div class="selector"
             v-for="selector in values"
             :id="'sel-' + selector.name.toLowerCase()"
             :class="{active: selector.name === cur_value}"
             @click="selectModel(selector.name)">
            {{selector.text ? selector.text : selector.name}}
        </div>
    </div>
</template>

<script>
    import Mixin from "../common/mixin";

    export default {
        name: 'selectorBar',
        props: ['values', 'cur_value', 'b_cars_car'],
        mixins: [Mixin],
        data() {
            return {
                bg_width: 0,
                bg_height: 0,
                bg_prev_left: 0,
                bg_offset: {
                    left: 0,
                    top: 0
                }
            }
        },
        mounted() {
            window.addEventListener('resize', this.getBgSize);
            this.$nextTick(() => {
                this.getBgSize();
            });
        },
        watch: {
            b_cars_car(value) {
                this.selectModel(value.name);
            }
        },
        computed: {
            c_bg_position() {
                const transform = this.device_platform === 'mobile' ?
                    'translateY(' + this.bg_offset.top + 'px)' :
                    'translateX(' + this.bg_offset.left + 'px)';
                return {
                    width: this.bg_width + 'px',
                    height: this.bg_height + 'px',
                    top: 0,
                    transform: transform
                };
            }
        },
        methods: {
            getBgSize() {
                let el = document.getElementById('sel-' + this.cur_value.toLowerCase());
                this.bg_width = el && el.offsetWidth;
                this.bg_height = el && el.offsetHeight;
                this.bg_prev_left = this.bg_offset.left;
                this.bg_offset = {
                    left: el.offsetLeft,
                    top: el.offsetTop
                };
            },
            selectModel(name) {
                this.$emit('selectModel', name);
                this.$nextTick(() => {
                    this.getBgSize();
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../styles/constants.scss";

    .tablet {
        .selector {
            font-size: 15px;
        }
    }

    .mobile {
        .selectors-bar {
            flex-direction: column;
        }

        .selector {
            width: 100%;
            height: 45px;
            font-size: 14px;
            padding: 0 30px 20px;
            max-width: 100%;

            &#sel-all {
                width: 100% !important;
                font-size: 30px;
            }

            &:last-child {
                margin-bottom: 10px;
            }
        }
    }

    .selectors-bar {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
    }

    .selector {
        max-width: 340px;
        height: 85px;
        display: flex;
        align-items: flex-end;
        font-weight: 100;
        font-size: 20px;
        padding: 0 20px 25px;
        cursor: pointer;
        position: relative;
        transition: color .2s;
        color: #fff;

        &#sel-all {
            width: 250px !important;
            font-size: 36px;
     
        }

        &.active {
            font-weight: 500;
        }

        &:nth-child(n+3) {
            text-transform: uppercase;
            padding: 0 30px 25px;
            font-size: 24px;
        }

        &:nth-child(2) {
            
        }

        &:after {
            position: absolute;
            content: '';
            background: url(../images/icons/line.png) no-repeat center;
            background-size: 100%;
            width: 40px;
            height: 40px;
            top: 25px;
            right: -20px;

            @media (max-width: 992px) {
                display: none;
            }
        }

        &:last-child {
            &:after {
                display: none;
            }
        }
    }

    .pseudo-background {
        // background-color: #1f6fb6;
        position: absolute;
        left: 0;
        transition: all .2s ease-out;
    }

    // #sel-atlas {
    //     order: 2;
    // }

</style>
