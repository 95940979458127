<template>
  <div class="block-contacts-container" :class="device_platform">
    <div class="header-text">
      КОНТАКТЫ
    </div>
    <observer @display="waitYmaps"></observer>
    <div id="ya-karto">
      <div class="input-container">
        <div class="logo-wrapper">
          <div 
            class="logo"
            @click="getCall({type: 'sale', form:'map'})"
          ></div>

          <div class="name-container" @click="getCall({type: 'sale', form:'map'})">
            <p class="dealer">{{CONSTANTS.dealer.toUpperCase()}}</p>
            <p class="dealer-text">Официальный дилер</p>
          </div>
        </div>
        <div class="office-contacts-container" v-for="office in CONSTANTS.offices" :key="office.address">
          <div class="phone-container">
            <div class="office-phone">
              <base-phone class="phone" :raw="office.phone_raw">{{ office.phone }}</base-phone>
            </div>
          </div>
          <div class="address-worktime-container">
            <div class="office-address-container">
              <div class="office-address" @click="scrollTo('contacts')">
                Г. ВЕЛИКИЙ НОВГОРОД, УЛ. СЕВЕРНАЯ, 2
              </div>
              <div class="office-worktime" v-html="office.worktime">
              </div>
            </div>
          </div>
        </div>
        <a target="_blank" href="https://yandex.ru/maps/?from=api-maps&ll=34.331901%2C57.349732&mode=routes&origin=jsapi_2_1_79&rtext=~59.961679%2C30.455179&rtt=auto&ruri=~ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgg1NzQzNDU1NhJO0KDQvtGB0YHQuNGPLCDQodCw0L3QutGCLdCf0LXRgtC10YDQsdGD0YDQsywg0YjQvtGB0YHQtSDQoNC10LLQvtC70Y7RhtC40LgsIDg2IgoNNaTzQRXC2G9C&z=6.9" class="link-nav">Проложить маршрут в навигаторе</a>
        <callback-input class="callback-input"
                        v-on="$listeners"
                        :horizontal="device_platform === 'tablet'"
                        :data="{type: 'sale', form: 'contacts'}">ОСТАВИТЬ
          ЗАЯВКУ
        </callback-input>
      </div>
      <div class="reviews-container" hidden>
          <div class="review-wrapper" v-for="review in reviews" :key="review.name">
            <div class="review-header">{{review.name}}</div>
            <div class="review-info-container">
              <div class="stars-row">
                <div class="star" v-for="i in 5" :key="i"></div>
              </div>
              <div class="review-date">{{review.date}}</div>
            </div>
            <div class="review-text" :class="{'expanded': review.expanded}">{{review.text}}</div>
            <div class="review-expander" @click="review.expanded = !review.expanded">{{review.expanded ? 'Скрыть' : 'Читать целиком'}}</div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
  import CallbackInput from './callback-form/callback-input';
  import Mask from '../common/mask';
  import Mixin from '../common/mixin';
  import Keyup from '../common/keyup';
  import Observer from './observer';
  import BasePhone from './base-phone.vue';

  export default {
    name: '',
    components: {CallbackInput, Observer, BasePhone},
    directives: {Mask, Keyup},
    mixins: [Mixin],
    props: [''],
    data() {
      return {
        offices: this.CONSTANTS.offices,
        reviews: [
          {
            name: 'Николай',
            text: 'Все́ понравилось. Быстро, честно и по делу',
            date: '18 янв 2022',
            expanded: false,
          },
          {
            name: 'Петр Смирнов',
            text: 'Приятный, приветливый, отзывчивый персонал, специалисты своего дела.иногда бывают выгодные спецпедложения',
            date: '12 янв 2022',
            expanded: false,
          },
          {
            name: 'Борис Коновалов',
            text: 'Сервис на высшем уровне!Спасибо руководителям за качественно налаженную работу персонала!',
            date: '11 янв 2022',
            expanded: false,
          },
          {
            name: 'Иван Рысухин',
            text: 'Отличный персонал, не стали втюхивать допов на миллионы денег!',
            date: '4 янв 2022',
            expanded: false,
          },
          {
            name: 'Роман Потапов',
            text: 'Хороший дилерский автоцентр, машину купил за пару дней в конце декабря 2020г. Сравнивал несколько официалов, цена примерно была везде одинаковая, но разные допы установленные на машине (на это обращайте внимание), торгуйтесь конечно, отстаивайте свои интересы, если хотите))!',
            date: '28 дек 2021',
            expanded: false,
          },
          {
            name: 'Мирослав Ц.',
            text: 'Приобретал здесь автомобиль, прохожу теперь здесь то, грамотные специалисты',
            date: '9 дек 2021',
            expanded: false,
          },
          {
            name: 'Дмитрий Г.',
            text: 'Хотел сказать спасибо менеджеру Николаю!Предисловие. Сам собственник бизнеса, отношусь к процессу покупки нового авто очень скрупулезно и поэтому взял с собой команду. Юрист + технарь.Вне зависимости от этих факторов, Николай отнёсся с понимаем. Все показал и рассказал. Конечно соблюдая рамки внутреннего регламента. Было пару небольших заминок, но понимаю процессы и отношусь к этому адекватно. Спасибо салону за более чем конкурентные условия и предложение.',
            date: '8 дек 2021',
            expanded: false,
          },
          {
            name: 'Камилла Зуева',
            text: 'Очень приветливый, дружный, постоянный состав сотрудников!',
            date: '7 дек 2021',
            expanded: false,
          },
          {
            name: 'Владимир Шуляпов',
            text: 'Менеджер все рассказал, показал. Но к сажелению пока не выбрали автомобиль',
            date: '5 дек 2021',
            expanded: false,
          },
          {
            name: 'Глеб Владимиров',
            text: 'Хороший салон, сотрудники к клиенту со всей душой, все на приличном уровне',
            date: '2 дек 2021',
            expanded: false,
          },
          {
            name: 'Iviv Ad',
            text: 'Хороший выбор автомобилей',
            date: '26 ноя 2021',
            expanded: false,
          },
          {
            name: 'Леся',
            text: 'Была на тест драйве, ребята большие молодцы. Теперь серьезно думаю о покупке',
            date: '18 ноя 2021',
            expanded: false,
          },
          {
            name: 'Лара Гущина',
            text: 'Огромное спасибо, что привезли наш автомобиль быстрее, чем мы ожидали. Вы лучшие!',
            date: '26 окт 2021',
            expanded: false,
          },
          {
            name: 'Eliana Likhacheva',
            text: 'Хорошая цена, искали долго в полной комплектации Джили Атлас. Купили у вас и не пожалели.',
            date: '26 окт 2021',
            expanded: false,
          },
          {
            name: 'Татьяна Мазепина',
            text: 'Хороший центр. Помогли выбрать автомобиль. Тест драйв на разных моделях не проблема. В отличии от других дилеров можно и в кредит и за наличный расчёт. За обслуживание пятёрка. Не хватает зоны ожидания и кофейного аппарата, ну по крайней мере для меня. Немного комфорта нужно добавить. Как правило в салоне проводишь не один час. А так все отлично',
            date: '22 окт 2021',
            expanded: false,
          },
          {
            name: 'Андрей Шаронов',
            text: 'Отличный сервис с отзывчивыми профессиональными сотрудниками, динамично развивающийся молодой бренд.',
            date: '15 окт 2021',
            expanded: false,
          },
          {
            name: 'Zaitsev',
            text: 'Приняли по гарантии, менеджеры компетентны и вежливы.',
            date: '16 сен 2021',
            expanded: false,
          },
          {
            name: 'Gleb Moiseev',
            text: 'По всем параметрам отличный салон!! Если покупать, то только здесь',
            date: '16 сен 2021',
            expanded: false,
          },
          {
            name: 'В Л',
            text: 'Ремонтировал свой автомобиль Джили Кулрей по Каско в техцентре (кузовной цех). Мастер приемщик Дмитрий Карабасов отличный специалист, спокойный, вежливый. Немного затянули со сроками, по вине поставщика запчастей. Но в целом все хорошо, в цвет попали идеально, были вопросы к зазорам, но были на месте исправлены).Попутно пообщался с ребятами из слесарного цеха, остался доволен по тем же причинам. моя оценка 5',
            date: '15 сен 2021',
            expanded: false,
          },
          {
            name: 'Open Spase',
            text: 'Очень удобно в одном месте столько брендов. Сразу все сравнил и пощупал.',
            date: '28 авг 2021',
            expanded: false,
          },
          {
            name: 'Артур В.',
            text: 'Сервис с нормальными ценами, никто не навязывает доп работы',
            date: '11 авг 2021',
            expanded: false,
          },
          {
            name: 'Lapin Arslan',
            text: 'Мы были очень придирчивы в процессе покупки, но менеджеры отработали наши запросы на 100 баллов, молодцы!',
            date: '9 авг 2021',
            expanded: false,
          },
          {
            name: 'Яков',
            text: 'Менеджер доброжелательный и тактичный, владеет информацией.',
            date: '8 мая 2021',
            expanded: false,
          },
          {
            name: 'Александр Б.',
            text: 'Отличный и отзывчивый персонал, все ясно и доступно объясняет.',
            date: '16 апр 2021',
            expanded: false,
          },
          {
            name: 'Дмитрий Обухов',
            text: 'Надёжный дилер, квалифицированный персонал, предоставляют выгодные спецпредложения, в том числе выгодный трейд-ин для медицинских работников, корпоративный отдел успешно сотрудничает с юрлицами. Автомобили надежные и комфортные, с конкурентной ценой.',
            date: '24 янв 2021',
            expanded: false,
          },
          {
            name: 'Colombina Colombina',
            text: 'Да, в Москве в Автостиль GEELY реально купить авто без навязанных доп. опций, по нынешним временам это - роскошь невиданная!))',
            date: '17 янв 2021',
            expanded: false,
          },
          {
            name: 'Mariya Buslova',
            text: 'Отличный менеджер отдела продаж Борис. Всегда на связи, ответит на любой вопрос. Покупали в этом салоне автомобиль, остались очень довольны',
            date: '18 ноя 2020',
            expanded: false,
          },
          {
            name: 'Евгений',
            text: 'Автосалон очень понравился, хорошая выставка машин, есть в наличии. Рекомендую обращаться к менеджеру Владимиру Галябовичу. Грамотный профессионал своего дела. Про продаваемые модели машин может рассказать, как плюсы, так и реальные минусы, которые могут быть.',
            date: '24 сен 2020',
            expanded: false,
          },
          {
            name: 'svetlana g.',
            text: 'Отличный центр! Грамотный персонал. Работают быстро, оперативно!',
            date: '27 авг 2020',
            expanded: false,
          },
          {
            name: 'Елена Кулева',
            text: 'Профессиональный и приветливый персонал. Подьезд удобный.',
            date: '19 авг 2020',
            expanded: false,
          },
          {
            name: 'Алена Ш.',
            text: 'Приобрели машинку у этого диллера... что я могу сказать?!... да огонь! Диллер бомба. Персонал абсолютно спокойный, приветливый, без лишних слов и навязок всякой фигни не нужной, все по делу! Пользуясь случаем выражаю ОГРОМНУЮ благодарность менеджеру Кириллу! Вы лучший менеджер и профессионал своего дела! Кирилл, спасибо вам за все! Спасибо за работу, за понимание и за такую крутую тачку что мы взяли!) Спасибо что вы нас поддерживали и не давали ни на секунду сомневаться в нашем выборе! Обожаю свою ласточку Атлас!!! Безумно благодарна вам за вашу работу и за такой чудесный день! Желаю всем побольше таких людей и менеджеров как вы! Душевный человек без всяких лишних навязок, все как надо! СПАСИБО Кирилл!!!! Взяли мы Джили Атлас 2,4 автомат, полный привод, беленькую ласточку нашу! Огромная благодарность Джили Кунцево! все грамотно, все четко, все быстро и по делу !',
            date: '18 авг 2020',
            expanded: false,
          },
          {
            name: 'Ирина С..',
            text: 'Здравствуйте, вчера купили машину GEELY COOLRAY 2020.Обменяли свои автомобиль по программе tr-in Ниссан Альмера. Выражаем свою благодарность менеджеру Галябович Владимир . Много допов получили. Все быстро и четко .Остались довольны.Рекомендуем',
            date: '20 июн 2020',
            expanded: false,
          },
          {
            name: 'Елена Теплякова',
            text: 'Супер. Менеджер Владимир, очень подробно все рассказал, посоветовал автомобиль. Остались довольны с мужем. Сервис на высоте! Рекомендую.',
            date: '15 июн 2020',
            expanded: false,
          },
          {
            name: 'Александр Ф.',
            text: 'Отличное место для покупки автомобиля',
            date: '8 апр 2020',
            expanded: false,
          },
          {
            name: 'Boris',
            text: 'Грамотные менеджеры. Отличное обслуживание . Профессиональная работа смежных отделов.',
            date: '2 апр 2020',
            expanded: false,
          },
          {
            name: 'Ян',
            text: 'Приятные, доброжелательные ребята менеджеры, здоровый, дружный коллектив.',
            date: '2 мар 2020',
            expanded: false,
          },
          {
            name: 'Gres',
            text: 'Замечательный салон приветливые менеджеры, оформились быстро вовремя без разводов',
            date: '2 мар 2020',
            expanded: false,
          },
          {
            name: 'Сергей Р.',
            text: 'Стали счастливыми обладателями Geely Atlas. Вечером приехали посмотрели, с нами очень хорошо поработал Николай Дзюба, все рассказал, показал, обьяснил. Мы внесли предоплату, обо всем договорились и уже на след день нам позвонил Николай и сказал, что машина готова, можно забирать. Никаких долгих ожиданий, все быстро, качественно и честно, за что большое спасибо Николаю и салону!',
            date: '6 янв 2020',
            expanded: false,
          }
        ]
      }
    },
    mounted() {
    },
    computed: {},
    methods: {
      initYandexMap() {
        const isMobile = {
          Android: function () {
            return navigator.userAgent.match(/Android/i);
          },
          BlackBerry: function () {
            return navigator.userAgent.match(/BlackBerry/i);
          },
          iOS: function () {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
          },
          Opera: function () {
            return navigator.userAgent.match(/Opera Mini/i);
          },
          Windows: function () {
            return navigator.userAgent.match(/IEMobile/i);
          },
          any: function () {
            return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
          }
        };
        let zoomControl = new ymaps.control.ZoomControl({
          options: {
            position: {
              left: 'auto',
              right: 10,
              top: 108,
            }
          }
        });

        let center_x = this.CONSTANTS.center_coords.x, center_y = this.CONSTANTS.center_coords.y;

        if (this.device_platform === 'tablet') {
          center_x = center_x;
          center_y = center_y;
        } else if (this.device_platform === 'mobile') {
          center_x = center_x;
          center_y = center_y;
        }

        let myMap = new ymaps.Map("ya-karto", {
          center: [center_y, center_x],
          zoom: 16,
          controls: []
        }, {
          searchControlProvider: 'yandex#search'
        });
        myMap.controls.add(zoomControl);

        this.offices.forEach((office, i) => {
          myMap.geoObjects
            .add(new ymaps.Placemark([office.coords.y, office.coords.x], {
              iconContent: office.name,
              hintContent: 'АВТОСТИЛЬ GEELY',
              balloonContent: 'Шоссе Революции, 86, Санкт-Петербург'
            }, {
              // preset: "islands#blueStretchyIcon",
              // iconColor: '#1e98ff',
                iconLayout: 'default#image',
                iconImageHref: require(`../images/map-baloon.svg`),
                iconImageSize: [59, 85],
                iconImageOffset: [-5, -78]
            }));
        })

        ;
        if (isMobile.any() || this.device_platform !== 'desktop') {
          myMap.behaviors.disable('drag');
        }
        myMap.behaviors.disable('scrollZoom');
      },
      waitYmaps() {
        let script = document.createElement('script');
        script.src = '//api-maps.yandex.ru/2.1/?load=package.standard&lang=ru_RU';
        document.head.appendChild(script);
        script.onload = () => {
          ymaps.ready(this.initYandexMap);
        };
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '../styles/constants';

  .mobile {
    &.block-contacts-container {
      margin-bottom: 460px;
      margin-top: 30px;

      .input-container {
        width: 320px;
        padding: 30px 10px;
        left: 50%;
        transform: translateX(-50%);
        top: 100%;
        box-shadow: none;
      }

      .office-contacts-container {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 20px;
      }

      .phone-container {
        margin-bottom: 20px;
      }

      .callback-input {
        width: 100%;
      }

      .dealer-text {
        width: 120%;
      }

      .dealer {
        font-size: 22px;
      }
    }
    .reviews-container {
      top: 1000px;
      right: 50%;
      transform: translateX(50%);
      box-shadow: none;
      background: none;
      max-height: 450px;
      max-width: 320px;
    }
  }

  .tablet {

    #ya-karto {
      margin-top: 280px;
    }
    .input-container {
      width: 100%;
      padding: 30px;
      left: 0;
      border-top: 10px solid $blue;
      top: -250px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-around;
    }

    .logos-container {
      width: 50%;
      max-width: 350px;
    }

    .office-contacts-container {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      text-align: right;
      margin-top: 0;
      width: 50%;
      max-width: 350px;
    }

    .callback-input {
      width: 100%;
      max-width: 800px;
    }
  }

  .block-contacts-container {
    width: 100%;
    position: relative;
    margin-top: 30px;
    max-width: 1920px;
  }

  .header-text {
    margin-bottom: 50px;
  }

  #ya-karto {
    width: 100%;
    height: 600px;
    position: relative;
    border-top: 10px solid $dark-blue;
  }

  .input-container {
    position: absolute;
    top: 50px;
    left: 120px;
    background: #FCFCFC;
    width: 436px;
    padding: 30px 24px 35px 32px;
    z-index: 1;
    box-shadow: 0 0 5px #ccc;
  }

  .logo-wrapper {
    display: flex;
    align-items: center;
  }

  .logo {
    background: url("../images/logos/blue/logo-horizontal.png") no-repeat center/contain;
    width: 192px;
    height: 27px;
    margin-right: 20px;
    box-sizing: content-box;
    cursor: pointer;
  }

  .name-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 19px;
    border-left: 0.73px solid $dark-blue;
    cursor: pointer;
  }

  .dealer {
    font-size: 18px;
    font-weight: 400;
    color: $dark-blue;
  }

  .dealer-text {
    font-size: 11px;
    font-weight: 300;
    color: $dark-blue;
  }

  .office-contacts-container {
    margin-top: 50px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  // .dealer {
  //   font-weight: 500;
  //   color: #1f6fb6;
  //   font-size: 24px;
  //   padding: 0px 7px 0px 0px;
  //   border-right: 2px solid #1f6fb6;
  // }

  // .dealer-text {
  //   font-weight: 400;
  //   font-size: 16px;
  //   color: #1f6fb6;
  //   padding: 5px 0px;
  // }

  .phone-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: 500;
    color: white;
    font-size: 19px;
    height: 30px;
    justify-content: center;
    margin-bottom: 15px;
  }

  .address-worktime-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .office-address {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 10px;
  }

  .office-worktime {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 20px;
  }

  .callback-input {
    width: 100%;
  }

  .link-nav {
    margin-bottom: 20px;
    display: inline-block;
    color: #000;
    border-bottom: 1px dashed #000;
    transition: all 0.5s;

    &:hover {
      color: #1f6fb6;
    }
  }

  .reviews-container {
    width: 350px;
    position: absolute;
    z-index: 1;
    top: 5px;
    right: 70px;
    padding: 10px;
    background: rgba(#fff, 0.8);
    box-shadow: 0 0 3px #ccc;
    max-height: 580px;
    overflow-y: auto;
  }

  .review-wrapper {
    padding: 10px;
    border-bottom: 1px solid #ccc;
    &:last-of-type {
      border-bottom: none;
    }
  }

  .review-header {
    font-size: 18px;
    font-weight: 500;
  }

  .review-info-container {
    display: flex;
    flex-direction: row;
    margin: 5px 0;
  }

  .review-date {
    margin-left: 20px;
    font-size: 14px;
    font-weight: 300;
    color: #9c99a0;
  }

  .stars-row {
    display: flex;
    flex-direction: row;
  }

  .star {
    background: url("../images/utils/star.svg") no-repeat center;
    background-size: contain;
    width: 15px;
    height: 15px;
  }

  .review-text {
    font-size: 15px;
    line-height: 18px;
    height: 55px;
    overflow-y: hidden;
    &.expanded {
      height: auto;
    }
  }

  .review-expander {
    color: $blue;
    cursor: pointer;
    margin-top: 5px;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
</style>
